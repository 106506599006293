.NavDropdown {
  position: relative;

  .NavItem {
    display: flex;
    padding: 8px 0;
    cursor: pointer;
    color: #333333;
    outline: 0;
  }

  .Menu {
    position: absolute;
    background-color: #f8fcfe;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    min-width: 170px;
    box-shadow: 0 4px 8px rgba(13, 121, 207, 0.3);
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 3;

    a {
      color: gray;
      padding: 8px;

      &:focus,
      &:hover {
        background: white;
        text-shadow: none;
        box-shadow: 0 2px 4px 0 rgba(13, 121, 207, 0.2);
        color: #58a7f9;
      }
    }
  }
}
