.NavItem {
  display: flex;
  padding: 8px 0;
  cursor: pointer;
  color: #333333;
  outline: 0;
}

.NavButtonItem {
  display: flex;
  padding: 8px 0;
  //cursor  : pointer;
  color: white;
  outline: 0;
}

@media (max-width: 959px) {
  .NavItem {
    padding: 12px 0;
  }
}

@media (max-width: 757px) {
  .NavItem {
    border-bottom: 1px solid #d3d3d3;
  }
}
