.ServicesSvg {
  &::after {
    content             : '';
    display             : block;
    position            : absolute;
    width               : 100%;
    height              : 60px;
    background-position : top;
    background-repeat   : no-repeat;
    background-size     : cover;
  }

  &.NarrowTopRightAfter {
    &::after {
      background-image : url('../../assets/images/curve-svgs/line-narrow-top-right.svg');
    }
  }

  &.NarrowTopLeftAfter {
    &::after {
      background-image : url('../../assets/images/curve-svgs/line-narrow-top-left.svg');
    }
  }

  &.NarrowTopLeftBlueAfter {
    &::after {
      background-image : url('../../assets/images/curve-svgs/line-narrow-top-left-blue.svg');
    }
  }
}

@media (min-width : 1280px) {
  .ServicesSvg {
    &::after {
      height : 120px;
    }
  }
}
