.mountain-svg {
  width               : 100%;
  height              : 80px;
  background-image    : url("../../assets/images/curve-svgs/mountain.svg");
  background-repeat   : no-repeat;
  background-position : top;
  background-size     : cover;
}

@media (min-width : 1280px) {
  .mountain-svg {
    height : 110px;
  }
}
