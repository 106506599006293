.WaveSvg {
  bottom              : 0;
  width               : 100%;
  height              : 600px;
  background-image    : url("../../assets/images/curve-svgs/wave.svg");
  background-repeat   : no-repeat;
  background-position : bottom;
  background-size     : contain;
  max-height          : 100%;
  transition          : height ease-in-out 0.2s;
}

@media (min-width : 1441px) {
  .WaveSvg {
    height           : 500px;
    background-image : url("../../assets/images/curve-svgs/wave-lg.svg");
  }
}

@media (max-width : 768px) {
  .WaveSvg {
    height         : auto;
    padding-bottom : 30px;
  }
}
