.Position {
  right  : 10px;
  bottom : 10px;
}

.RoundSize {
  width  : 3.75rem;
  height : 3.75rem;
}

.FadeUpAnimate {
  opacity           : 0;
  animation         : fadeInUp .5s ease-in-out 0s 1 forwards alternate;
  -webkit-animation : fadeInUp .5s ease-in-out 0s 1 forwards alternate;
}

@keyframes fadeInUp {
  from {
    transform : translate3d(0, 40px, 0)
  }

  to {
    transform : translate3d(0, 0, 0);
    opacity   : 1
  }
}
