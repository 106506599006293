.Navbar {
  padding: 0 24px;
  color: white;
  min-height: 3rem;
  height: 3.25px;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #d3d3d3;

  .WogaaLogo {
    img {
      height: 100%;
      width: 2.5rem;
    }
  }

  .Hamburger {
    display: flex;
    color: white;

    &:focus,
    &:hover {
      text-decoration: none;
      text-shadow: 0 2px 5px dark-blue;
    }
  }
}

.HamburgerContainer {
  display: inline-block;
  padding: 5px 0px 5px 10px;
}

.Panel {
  height: 100vh;
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
  animation: show-animation 400ms linear;

  &.SubPanel {
    a:first-child {
      text-shadow: none;
      justify-content: flex-start;

      .faded {
        opacity: 0.5;
      }
    }

    a:not(:first-child) {
      padding-left: 32px;
    }
  }
}

.PanelItem {
  padding: 0px 25px;
}

.PanelButtons {
  padding: 25px 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
}

.verticalRule {
  border-left: 1px solid #d3d3d3;
  height: 32px;
  padding-right: 10px;
}

.sgtsContainer {
  display: inline-block;
  padding: 3px 10px;
  border-right: 1px solid #d3d3d3;
}

.sgtsWidget {
  display: flex;
  color: #666666;
}

@keyframes show-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
