.Navbar {
  padding: 0 24px;
  min-height: 40px;
  height: 80px;
  transition: height 200ms ease-out;
  background: white;

  .WogaaLogo {
    margin-right: 24px;

    img {
      height: 100%;
      width: 100px;
      transition: all 200ms ease-out;
    }
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }

  > *:nth-last-child(3) {
    margin-left: auto;
  }

  &.Shrunk {
    padding: 0 24px;
    border-bottom: 1px solid #d3d3d3;

    .WogaaLogo {
      img {
        width: 100px;
      }
    }
  }

  .DropdownClass {
    position: relative;
  }
}
